// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dj_zr";
export var servicesToolkitSection__card = "dj_zs";
export var servicesToolkitSection__icons = "dj_zt";
export var servicesToolkitSection__serviceToolkitIcon1 = "dj_zw";
export var servicesToolkitSection__serviceToolkitIcon10 = "dj_zH";
export var servicesToolkitSection__serviceToolkitIcon11 = "dj_zJ";
export var servicesToolkitSection__serviceToolkitIcon12 = "dj_zK";
export var servicesToolkitSection__serviceToolkitIcon13 = "dj_zL";
export var servicesToolkitSection__serviceToolkitIcon14 = "dj_zM";
export var servicesToolkitSection__serviceToolkitIcon15 = "dj_zN";
export var servicesToolkitSection__serviceToolkitIcon16 = "dj_zP";
export var servicesToolkitSection__serviceToolkitIcon17 = "dj_zQ";
export var servicesToolkitSection__serviceToolkitIcon18 = "dj_zR";
export var servicesToolkitSection__serviceToolkitIcon19 = "dj_zS";
export var servicesToolkitSection__serviceToolkitIcon2 = "dj_zx";
export var servicesToolkitSection__serviceToolkitIcon20 = "dj_zT";
export var servicesToolkitSection__serviceToolkitIcon3 = "dj_zy";
export var servicesToolkitSection__serviceToolkitIcon4 = "dj_zz";
export var servicesToolkitSection__serviceToolkitIcon5 = "dj_zB";
export var servicesToolkitSection__serviceToolkitIcon6 = "dj_zC";
export var servicesToolkitSection__serviceToolkitIcon7 = "dj_zD";
export var servicesToolkitSection__serviceToolkitIcon8 = "dj_zF";
export var servicesToolkitSection__serviceToolkitIcon9 = "dj_zG";
export var servicesToolkitSection__sprite = "dj_zv";
export var servicesToolkitSection__title = "dj_zV";